import { Bar } from 'react-chartjs-2'
import ChartContainer from './ChartContainer'

interface BarChartProps {
  labels: string[]
  data: number[]
  title: string
}

const BarChart = ({ labels, data, title }: BarChartProps) => {
  if (!data) return null

  const biggestNum = Math.max(...data)
  const nearestBigNum = 10

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: Math.ceil(biggestNum / 10) * nearestBigNum,
        stepSize: 200,
        grid: {
          tickLength: 8,
          tickColor: 'transparent',
          color: '#919191',
          lineWidth: 1,
          borderWidth: 1,
          borderColor: '#919191'
        }
      },
      x: {
        stepSize: 200,

        grid: {
          display: false,
          borderWidth: 1,
          borderColor: '#919191'
        }
      }
    },
    elements: {
      point: {
        pointStyle: 'circle'
      }
    },
    plugins: {
      datalabels: {
        color: '#fff'
      },
      legend: {
        display: false
      },
      title: {
        display: false
      }
    }
  }

  const colors = [
    '#e60049',
    '#0bb4ff',
    '#50e991',
    '#e6d800',
    '#9b19f5',
    '#ffa300',
    '#dc0ab4',
    '#b3d4ff',
    '#00bfa0',
    '#7c1158',
    '#4421af',
    '#1a53ff',
    '#b2e061',
    '#bd7ebe',
    '#ffb55a',
    '#ffee65',
    '#27aeef'
  ]

  const defaultData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: colors
      }
    ]
  }

  return (
    <ChartContainer title={title}>
      <Bar options={options} data={defaultData} />
    </ChartContainer>
  )
}

export default BarChart
