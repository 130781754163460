import { IconLogo } from 'assets/images'
import {
  AudioItem,
  CommentItem,
  ImageGalleryItem,
  Label,
  WeatherCard
} from 'components'
import { t } from 'i18n'
import moment from 'moment'
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useState
} from 'react'
import { If } from 'react-extras'
import {
  BikeEquipmentType,
  Comment,
  EventSource,
  PersonalInformationConsent,
  Report,
  Seasons,
  SkiEquipmentType
} from 'store/ducks'
import {
  filterPropsByValue,
  getPainLevel,
  toCamelCase
} from '../../utils/formatters'
import { ImageGalleryContainer } from '../Report/components/form-components/FormTitle/styled'
import ReportOverviewCard from './content-card'
import MonitoringCard from './monitoring-card'
import './report-overview.css'
import {
  ColContainer,
  PageBreak,
  ReportOverViewPrintContainer,
  ReportOverviewBodyContainer,
  ReportOverviewBodyContentContainer,
  ReportOverviewContentContainer,
  ReportOverviewInfoGroup,
  ReportOverviewLabel,
  ReportOverviewVerticalContent,
  RowContainer,
  WitnessContainer
} from './styled'

interface Props {
  report?: Report
}

const ReportOverViewFunction: ForwardRefRenderFunction<
  HTMLDivElement,
  Props
> = (props, ref) => {
  const { report } = props

  const MAPS_API_KEY = process.env.REACT_APP_GOOGLE_KEY
  const MAPS_STATIC_URL = `https://maps.googleapis.com/maps/api/staticmap?key=${MAPS_API_KEY}&center=[LAT],[LNG]&zoom=18&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Cvisibility:off&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels%7Cvisibility:on&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Cvisibility:on&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=640x380&markers=color:red%7C[LAT],[LNG]`

  const [staticMap, setStaticMap] = useState<string>()

  const filterCommentsByType = (
    comments: Comment[],
    type: EventSource
  ): Comment[] => {
    let filtered: Comment[] = []

    if (comments && comments.length > 0) {
      filtered = comments.filter(cmt => cmt.source === type)
      if (filtered.length > 0) {
        return filtered
      }
    }

    return filtered
  }

  useEffect(() => {
    if (report?.trailMap && report?.trailMap?.includeLocation) {
      setStaticMap(
        MAPS_STATIC_URL.replace(
          '[LAT]',
          String(report?.trailMap?.geoLocation?.lat)
        )
          .replace('[LNG]', String(report?.trailMap?.geoLocation?.lng))
          .replace('[LAT]', String(report?.trailMap?.geoLocation?.lat))
          .replace('[LNG]', String(report?.trailMap?.geoLocation?.lng))
      )
    }
  }, [MAPS_STATIC_URL, report?.trailMap])

  return (
    <ReportOverViewPrintContainer ref={ref}>
      <IconLogo className="report-overview-logo" />
      <ReportOverviewContentContainer
        className="content-container"
        bgColor={'#385ebf'}
      >
        <ReportOverviewVerticalContent className="vertical-container">
          <ReportOverviewLabel color="#fff" size={13}>
            {t('injuryReportNumber')}
          </ReportOverviewLabel>
          <ReportOverviewLabel color="#fff" size={25} weight={600}>
            {report?.SK}
          </ReportOverviewLabel>
          <ReportOverviewLabel color="#fff" size={13}>
            {t('version')}
          </ReportOverviewLabel>
          <ReportOverviewLabel color="#fff" size={25} weight={600}>
            {report?.version}
          </ReportOverviewLabel>
          <ReportOverviewLabel color="#fff" size={13}>
            {t('creationDate')}
          </ReportOverviewLabel>
          <ReportOverviewLabel color="#fff" size={18} weight={600}>
            {moment(report?.createdAt).format('DD.MM.YYYY HH:mm')}
          </ReportOverviewLabel>
          <If
            condition={
              report?.personalInformation?.personalInformationConsent ===
              PersonalInformationConsent.PATIENT_HAS_NOT_GIVEN_CONSENT
            }
          >
            <ReportOverviewLabel
              color="#fff"
              size={25}
              weight={600}
              style={{
                marginTop: 20
              }}
            >
              {t('consentNotGiven')}
            </ReportOverviewLabel>
          </If>
          <If
            condition={
              !!report?.personalInformation
                ?.personalInformationDeniedExplanation
            }
          >
            <ReportOverviewLabel color="#fff" size={13}>
              {report?.personalInformation.personalInformationDeniedExplanation}
            </ReportOverviewLabel>
          </If>
        </ReportOverviewVerticalContent>
        <ReportOverviewVerticalContent className="vertical-container">
          <ReportOverviewLabel color="#fff" size={25}>
            {t('patient')}
          </ReportOverviewLabel>
          <ReportOverviewInfoGroup>
            <ReportOverviewLabel color="#fff" size={13}>
              {t('name')}
            </ReportOverviewLabel>
            <ReportOverviewLabel color="#fff" size={18}>
              {report?.personalInformation?.firstName +
                ' ' +
                report?.personalInformation?.lastName || ''}
            </ReportOverviewLabel>
          </ReportOverviewInfoGroup>
          <ReportOverviewInfoGroup>
            <ReportOverviewLabel color="#fff" size={13}>
              {t('gender')}
            </ReportOverviewLabel>
            <ReportOverviewLabel color="#fff" size={18}>
              {report?.personalInformation?.gender
                ? t(report?.personalInformation?.gender)
                : '-'}
            </ReportOverviewLabel>
          </ReportOverviewInfoGroup>
          <ReportOverviewInfoGroup>
            <ReportOverviewLabel color="#fff" size={13}>
              {t('birthDate')}
            </ReportOverviewLabel>
            <ReportOverviewLabel color="#fff" size={18}>
              {report?.personalInformation?.birthDate
                ? moment(report?.personalInformation?.birthDate).format(
                    'DD.MM.YYYY'
                  )
                : '-'}
            </ReportOverviewLabel>
          </ReportOverviewInfoGroup>
          <ReportOverviewInfoGroup>
            <ReportOverviewLabel color="#fff" size={13}>
              {t('nationality')}
            </ReportOverviewLabel>
            <ReportOverviewLabel color="#fff" size={18}>
              {report?.personalInformation?.nationality
                ? t(report?.personalInformation?.nationality)
                : '-'}
            </ReportOverviewLabel>
          </ReportOverviewInfoGroup>
          <ReportOverviewInfoGroup>
            <ReportOverviewLabel color="#fff" size={13}>
              {t('phoneNumber')}
            </ReportOverviewLabel>
            <ReportOverviewLabel color="#fff" size={18}>
              {report?.personalInformation?.phoneNumber || '-'}
            </ReportOverviewLabel>
          </ReportOverviewInfoGroup>
          <ReportOverviewInfoGroup>
            <ReportOverviewLabel color="#fff" size={13}>
              {t('address')}
            </ReportOverviewLabel>
            <ReportOverviewLabel
              weight={600}
              padding={'2px 0'}
              color="#fff"
              size={18}
            >
              {report?.personalInformation?.address}
            </ReportOverviewLabel>
            <ReportOverviewLabel
              weight={600}
              padding={'2px 0'}
              color="#fff"
              size={18}
            >
              {report?.personalInformation?.postalCode}
            </ReportOverviewLabel>
            <ReportOverviewLabel
              weight={600}
              padding={'2px 0'}
              color="#fff"
              size={18}
            >
              {report?.personalInformation?.place} {' - '}{' '}
              {report?.personalInformation?.country}
            </ReportOverviewLabel>
          </ReportOverviewInfoGroup>
        </ReportOverviewVerticalContent>
        <If
          condition={
            report?.personalInformation?.relatives &&
            report?.personalInformation?.relatives?.length > 0
              ? true
              : false
          }
        >
          {report &&
            report.personalInformation?.relatives &&
            report.personalInformation?.relatives.map((relative, index) => (
              <ReportOverviewVerticalContent
                className="vertical-container"
                key={index}
              >
                <ReportOverviewLabel color="#fff" size={25}>
                  {t('relative')}
                </ReportOverviewLabel>
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel color="#fff" size={13}>
                    {t('relativeRole')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel color="#fff" size={18}>
                    {t(relative.role)}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel color="#fff" size={13}>
                    {t('name')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel color="#fff" size={18}>
                    {relative.fullName}
                  </ReportOverviewLabel>

                  <ReportOverviewLabel color="#fff" size={13}>
                    {t('phoneNumber')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel color="#fff" size={18}>
                    {relative.phoneNumber || '-'}
                  </ReportOverviewLabel>

                  <ReportOverviewLabel color="#fff" size={13}>
                    {t('email')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel color="#fff" size={18}>
                    {relative.email || '-'}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
              </ReportOverviewVerticalContent>
            ))}
        </If>
      </ReportOverviewContentContainer>
      <ReportOverviewBodyContainer>
        <ReportOverviewBodyContentContainer>
          <ReportOverviewCard title="allergies">
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel color="#2b2b2b" size={16}>
                {report?.personalInformation?.allergies || '-'}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
          </ReportOverviewCard>

          <ReportOverviewCard title="fixedMedications">
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel color="#2b2b2b" size={16}>
                {report?.personalInformation?.medications || '-'}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
          </ReportOverviewCard>

          <ReportOverviewCard title="skills">
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('skillsOnUsedEquipment')}
              </ReportOverviewLabel>
              <ReportOverviewLabel size={16}>
                {report?.personalInformation?.skills?.level ? (
                  <>
                    &bull;{' '}
                    {t(
                      report?.personalInformation?.skills?.level?.toLowerCase()
                    )}
                  </>
                ) : (
                  <>-</>
                )}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('introduction')}
              </ReportOverviewLabel>
              <ReportOverviewLabel size={16}>
                &bull;{' '}
                {t(
                  report?.personalInformation?.skills?.introduction
                    ? 'yes'
                    : 'no'
                )}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
          </ReportOverviewCard>
          <ReportOverviewCard title="equipment">
            <RowContainer>
              <RowContainer>
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {report?.season === Seasons.WINTER
                      ? t('skiEquipment')
                      : t('bikeEquipment')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={13}
                    weight={600}
                  >
                    {t('rented')}
                  </ReportOverviewLabel>
                  <If
                    condition={
                      report?.season === Seasons.WINTER &&
                      report?.personalInformation?.equipments?.skiEquipment
                        ?.type
                        ? true
                        : false
                    }
                  >
                    <ReportOverviewLabel size={16}>
                      &bull;{' '}
                      {report?.personalInformation?.equipments?.skiEquipment
                        ?.type !== SkiEquipmentType.OTHER
                        ? t(
                            toCamelCase(
                              report?.personalInformation?.equipments
                                ?.skiEquipment?.type
                            )
                          )
                        : report?.personalInformation?.equipments?.skiEquipment
                            ?.otherSkiEquipment}
                    </ReportOverviewLabel>
                  </If>
                  <If
                    condition={
                      report?.season === Seasons.SUMMER &&
                      report?.personalInformation?.equipments?.bikeEquipment
                        ?.type
                        ? true
                        : false
                    }
                  >
                    <ReportOverviewLabel size={16}>
                      &bull;{' '}
                      {report?.personalInformation?.equipments?.bikeEquipment
                        ?.type !== BikeEquipmentType.OTHER
                        ? t(
                            toCamelCase(
                              report?.personalInformation?.equipments
                                ?.bikeEquipment?.type
                            )
                          )
                        : report?.personalInformation?.equipments?.bikeEquipment
                            ?.otherBikeEquipment}
                    </ReportOverviewLabel>
                  </If>
                  <ReportOverviewLabel size={16}>
                    &bull;{' '}
                    {t(
                      report?.personalInformation?.equipments?.isRented?.toLowerCase()
                    )}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
              </RowContainer>
              <RowContainer>
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('safetyEquipment')}
                  </ReportOverviewLabel>
                  <If condition={report?.season === Seasons.WINTER}>
                    <RowContainer>
                      <ColContainer>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('isEquipmentRented')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments?.safetyEquipments?.isRented?.toString()
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                      <ColContainer>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('helmet')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments
                              ?.safetyEquipments?.helmet
                              ? 'yes'
                              : 'no'
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                      <ColContainer>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('backProtector')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments
                              ?.safetyEquipments?.backProtector
                              ? 'yes'
                              : 'no'
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                      <ColContainer>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('wristProtector')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments
                              ?.safetyEquipments?.wristProtector
                              ? 'yes'
                              : 'no'
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                    </RowContainer>
                  </If>
                  <If condition={report?.season === Seasons.SUMMER}>
                    <RowContainer>
                      <ColContainer>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('isEquipmentRented')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments?.safetyEquipments?.isRented?.toString()
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                      <ColContainer style={{ padding: 10 }}>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('halfHelmet')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments
                              ?.safetyEquipments?.halfHelmet
                              ? 'yes'
                              : 'no'
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                      <ColContainer style={{ padding: 10 }}>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('helmet')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments
                              ?.safetyEquipments?.helmet
                              ? 'yes'
                              : 'no'
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                      <ColContainer style={{ padding: 10 }}>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('gloves')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments
                              ?.safetyEquipments?.gloves
                              ? 'yes'
                              : 'no'
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                      <ColContainer style={{ padding: 10 }}>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('backPlate')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments
                              ?.safetyEquipments?.backPlate
                              ? 'yes'
                              : 'no'
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                      <ColContainer style={{ padding: 10 }}>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('armor')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments
                              ?.safetyEquipments?.armor
                              ? 'yes'
                              : 'no'
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                      <ColContainer style={{ padding: 10 }}>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('legKneeProtector')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments
                              ?.safetyEquipments?.legKneeProtector
                              ? 'yes'
                              : 'no'
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                      <ColContainer style={{ padding: 10 }}>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('neckCollar')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments
                              ?.safetyEquipments?.neckCollar
                              ? 'yes'
                              : 'no'
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                      <ColContainer style={{ padding: 10 }}>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('sack')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments
                              ?.safetyEquipments?.sack
                              ? 'yes'
                              : 'no'
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                      <ColContainer style={{ padding: 10 }}>
                        <ReportOverviewLabel
                          padding={'5px 0 10px 0'}
                          size={13}
                          weight={600}
                        >
                          {t('elbowProtection')}
                        </ReportOverviewLabel>
                        <ReportOverviewLabel size={16}>
                          &bull;{' '}
                          {t(
                            report?.personalInformation?.equipments
                              ?.safetyEquipments?.elbowProtection
                              ? 'yes'
                              : 'no'
                          )}
                        </ReportOverviewLabel>
                      </ColContainer>
                    </RowContainer>
                  </If>
                </ReportOverviewInfoGroup>
              </RowContainer>
            </RowContainer>
          </ReportOverviewCard>

          {/* Skade */}
          <PageBreak className="page-break" />
          <ReportOverviewCard title="injury">
            <ColContainer>
              <ReportOverviewInfoGroup>
                <ReportOverviewLabel
                  padding={'5px 0 10px 0'}
                  size={18}
                  weight={600}
                >
                  {t('assumedInjury')}
                </ReportOverviewLabel>

                {report?.injury?.assumedInjury &&
                  filterPropsByValue(report?.injury?.assumedInjury, true).map(
                    (field, index) => (
                      <ReportOverviewLabel key={index} size={16}>
                        &bull;{' '}
                        {field.keyName === 'other'
                          ? report?.injury?.assumedInjury?.otherAddComment
                          : t(field.keyName)}
                      </ReportOverviewLabel>
                    )
                  )}
              </ReportOverviewInfoGroup>

              <If
                condition={
                  report?.injury?.pictures &&
                  report?.injury?.pictures?.length > 0
                    ? true
                    : false
                }
              >
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('picturesForTheInjury')}
                  </ReportOverviewLabel>

                  <ImageGalleryContainer>
                    {report?.injury?.pictures?.map((image, index) => (
                      <ImageGalleryItem key={index} path={image.path} />
                    ))}
                  </ImageGalleryContainer>
                </ReportOverviewInfoGroup>
              </If>
            </ColContainer>

            <ColContainer>
              <ReportOverviewInfoGroup>
                <ReportOverviewLabel
                  padding={'5px 0 10px 0'}
                  size={18}
                  weight={600}
                >
                  {t('injuredBodypart')}
                </ReportOverviewLabel>
                {report?.injury?.injuredBodyPart &&
                  filterPropsByValue(report?.injury?.injuredBodyPart, true).map(
                    (field, index) => (
                      <ReportOverviewLabel key={index} size={16}>
                        &bull;{' '}
                        {field.keyName === 'other'
                          ? report?.injury?.injuredBodyPart?.otherAddComment
                          : t(field.keyName)}
                      </ReportOverviewLabel>
                    )
                  )}
              </ReportOverviewInfoGroup>

              <If
                condition={
                  report?.comments &&
                  filterCommentsByType(report?.comments, EventSource.INJURY)
                    .length > 0
                    ? true
                    : false
                }
              >
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('comments')}
                  </ReportOverviewLabel>

                  {report?.comments &&
                    filterCommentsByType(
                      report?.comments,
                      EventSource.INJURY
                    ).map((comment, index) => (
                      <CommentItem
                        key={index}
                        title={comment.title}
                        description={comment.comment}
                      />
                    ))}
                </ReportOverviewInfoGroup>
              </If>
            </ColContainer>
          </ReportOverviewCard>

          <ReportOverviewCard title="sequenceOfEvents">
            <ColContainer>
              <ReportOverviewInfoGroup>
                <ReportOverviewLabel
                  padding={'5px 0 10px 0'}
                  size={18}
                  weight={600}
                >
                  {t('whereDidTheInjuryOccur')}
                </ReportOverviewLabel>
                {report?.sequenceOfEvents?.injuryLocation &&
                  filterPropsByValue(
                    report?.sequenceOfEvents?.injuryLocation,
                    true
                  ).map((field, index) => (
                    <ReportOverviewLabel key={index} size={16}>
                      &bull;{' '}
                      {field.keyName === 'other'
                        ? report?.sequenceOfEvents?.injuryLocation
                            ?.injuryLocationOther
                        : t(field.keyName)}
                    </ReportOverviewLabel>
                  ))}
              </ReportOverviewInfoGroup>
              <ReportOverviewInfoGroup>
                <ReportOverviewLabel
                  padding={'5px 0 10px 0'}
                  size={18}
                  weight={600}
                >
                  {t('descriptionOfInjurySite')}
                </ReportOverviewLabel>
                <ReportOverviewLabel>
                  {report?.sequenceOfEvents?.eventExplanationLocation}
                </ReportOverviewLabel>
              </ReportOverviewInfoGroup>
              <ReportOverviewInfoGroup>
                <ReportOverviewLabel
                  padding={'5px 0 10px 0'}
                  size={18}
                  weight={600}
                >
                  {t('picturesForTheSequenceOfEvents')}
                </ReportOverviewLabel>

                <ImageGalleryContainer>
                  {report?.sequenceOfEvents?.pictures?.map((image, index) => (
                    <ImageGalleryItem key={index} path={image.path} />
                  ))}
                </ImageGalleryContainer>
              </ReportOverviewInfoGroup>
              <ReportOverviewInfoGroup>
                <ReportOverviewLabel
                  padding={'5px 0 10px 0'}
                  size={18}
                  weight={600}
                >
                  {t('sequenceOfEventsExplanation')}
                </ReportOverviewLabel>
                <ReportOverviewLabel>
                  {report?.sequenceOfEvents?.eventExplanation}
                </ReportOverviewLabel>
                <RowContainer>
                  <ColContainer>
                    <If
                      condition={
                        report?.sequenceOfEvents?.collisionWithObject ?? false
                      }
                    >
                      <ReportOverviewLabel
                        padding={'5px 0 10px 0'}
                        size={13}
                        weight={600}
                      >
                        {t('collisionWithObject')}
                      </ReportOverviewLabel>
                      <ReportOverviewLabel size={16}>
                        &bull; {t('yes')}
                      </ReportOverviewLabel>
                    </If>
                  </ColContainer>
                  <ColContainer>
                    <If
                      condition={
                        report?.sequenceOfEvents?.fallYourself ?? false
                      }
                    >
                      <ReportOverviewLabel
                        padding={'5px 0 10px 0'}
                        size={13}
                        weight={600}
                      >
                        {t('fallYourself')}
                      </ReportOverviewLabel>
                      <ReportOverviewLabel size={16}>
                        &bull; {t('yes')}
                      </ReportOverviewLabel>
                    </If>
                  </ColContainer>
                  <ColContainer>
                    <If
                      condition={
                        report?.sequenceOfEvents?.hitByAnotherPerson ?? false
                      }
                    >
                      <ReportOverviewLabel
                        padding={'5px 0 10px 0'}
                        size={13}
                        weight={600}
                      >
                        {t('hitByAnotherPerson')}
                      </ReportOverviewLabel>
                      <ReportOverviewLabel size={16}>
                        &bull; {t('yes')}
                      </ReportOverviewLabel>
                    </If>
                  </ColContainer>
                  <ColContainer>
                    <If
                      condition={
                        report?.sequenceOfEvents?.runOverAnotherPerson ?? false
                      }
                    >
                      <ReportOverviewLabel
                        padding={'5px 0 10px 0'}
                        size={13}
                        weight={600}
                      >
                        {t('runOverAnotherPerson')}
                      </ReportOverviewLabel>
                      <ReportOverviewLabel size={16}>
                        &bull; {t('yes')}
                      </ReportOverviewLabel>
                    </If>
                  </ColContainer>
                </RowContainer>
              </ReportOverviewInfoGroup>
              <ReportOverviewInfoGroup>
                <ReportOverviewLabel
                  padding={'5px 0 10px 0'}
                  size={18}
                  weight={600}
                >
                  {t('witnesses')}
                </ReportOverviewLabel>

                {report?.sequenceOfEvents?.witnesses &&
                  report?.sequenceOfEvents?.witnesses.map((witness, index) => (
                    <WitnessContainer key={index}>
                      <Label style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <small>{t('name')}: </small>
                      </Label>
                      <Label style={{ paddingTop: 5, paddingBottom: 10 }}>
                        {witness.fullName}
                      </Label>
                      <Label style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <small>{t('phoneNumber')}: </small>
                      </Label>
                      <Label style={{ paddingTop: 5, paddingBottom: 10 }}>
                        {witness.phoneNumber}
                      </Label>
                      <Label style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <small>{t('explanation')}: </small>
                      </Label>
                      <Label style={{ paddingTop: 5, paddingBottom: 10 }}>
                        {witness.explanation}
                      </Label>
                      <If
                        condition={
                          witness?.signature && witness.signature.length > 0
                            ? true
                            : false
                        }
                      >
                        <Label style={{ paddingTop: 5, paddingBottom: 5 }}>
                          <small>{t('signature')}: </small>
                        </Label>
                        <img
                          src={witness.signature}
                          alt="signature"
                          width={200}
                          height={100}
                        />
                      </If>

                      <If
                        condition={
                          report?.sequenceOfEvents?.witnesses &&
                          report?.sequenceOfEvents?.witnesses?.length > 1 &&
                          report?.sequenceOfEvents?.witnesses?.length ===
                            index + 1
                            ? true
                            : false
                        }
                      >
                        <hr style={{ width: '100%' }} />
                      </If>
                    </WitnessContainer>
                  ))}
              </ReportOverviewInfoGroup>
            </ColContainer>
            <ColContainer>
              <ReportOverviewInfoGroup
                style={{ flex: 'normal', flexGrow: 'unset' }}
              >
                <ReportOverviewLabel
                  padding={'5px 0 10px 0'}
                  size={18}
                  weight={600}
                >
                  {t('recordingForTheSequenceOfEvents')}
                </ReportOverviewLabel>

                {report?.sequenceOfEvents?.recordings?.map((audio, index) => (
                  <AudioItem
                    key={index}
                    duration={audio.duration}
                    date={audio.fileDate}
                    onPress={() => console.log('open')}
                  />
                ))}
              </ReportOverviewInfoGroup>
              <ReportOverviewInfoGroup>
                <ReportOverviewLabel
                  padding={'5px 0 10px 0'}
                  size={18}
                  weight={600}
                >
                  {t('comments')}
                </ReportOverviewLabel>

                {report?.comments &&
                  filterCommentsByType(
                    report?.comments,
                    EventSource.SEQUENCE_OF_EVENTS
                  ).map((comment, index) => (
                    <CommentItem
                      key={index}
                      title={comment.title}
                      description={comment.comment}
                    />
                  ))}
              </ReportOverviewInfoGroup>
            </ColContainer>
          </ReportOverviewCard>
          <ReportOverviewCard title="state">
            <ColContainer style={{ justifyContent: 'space-between' }}>
              <RowContainer>
                <ReportOverviewInfoGroup style={{ width: '50%' }}>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('highEnergy')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    &bull; {t(toCamelCase(report?.state?.highEnergy))}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
                <ReportOverviewInfoGroup style={{ width: '50%' }}>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('consciousness')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    &bull; {t(toCamelCase(report?.state?.consciousness))}
                  </ReportOverviewLabel>
                  {report?.state?.consciousnessLevel ? (
                    <>
                      <ReportOverviewLabel
                        padding={'5px 0 10px 0'}
                        size={18}
                        weight={600}
                      >
                        {t`wasThePersonUnconsciousAtAnyPoint`}
                      </ReportOverviewLabel>
                      <ReportOverviewLabel size={16}>
                        {report?.state?.consciousnessLevel}
                      </ReportOverviewLabel>
                    </>
                  ) : null}
                </ReportOverviewInfoGroup>
              </RowContainer>
              <RowContainer>
                <ReportOverviewInfoGroup style={{ width: '50%' }}>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {`${t('circulation')} 1/2`}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    <b>&bull; {t`pulse`}:</b> {t(report?.state?.pulse)}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    <b>&bull; {t`pulseSource`}:</b>{' '}
                    {report?.state?.pulseSource?.throat &&
                    report?.state?.pulseSource?.wrist ? (
                      <>
                        {t`throat`} <b>|</b> {t`wrist`}{' '}
                      </>
                    ) : (
                      <>
                        {t(report?.state?.pulseSource?.throat ? 'throat' : '')}
                        {t(report?.state?.pulseSource?.wrist ? 'wrist' : '')}
                      </>
                    )}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
                <ReportOverviewInfoGroup style={{ width: '50%' }}>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {`${t('circulation')} 2/2`}
                  </ReportOverviewLabel>

                  {report?.state?.circulation?.pale ? (
                    <ReportOverviewLabel size={16}>
                      &bull; {t`pale`}
                    </ReportOverviewLabel>
                  ) : (
                    ''
                  )}
                  {report?.state?.circulation?.clammyCold ? (
                    <ReportOverviewLabel size={16}>
                      &bull; {t`clammy/cold`}
                    </ReportOverviewLabel>
                  ) : (
                    ''
                  )}
                  {report?.state?.circulation?.thirsty ? (
                    <ReportOverviewLabel size={16}>
                      &bull; {t`thirsty`}
                    </ReportOverviewLabel>
                  ) : (
                    ''
                  )}
                </ReportOverviewInfoGroup>
              </RowContainer>

              <RowContainer>
                <ReportOverviewInfoGroup style={{ width: '50%' }}>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {`${t('breathing')}`}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    &bull;{' '}
                    {report?.state?.breathing === 'Other'
                      ? report?.state?.breathingCommentOther
                      : report?.state?.breathing
                        ? t(report?.state?.breathing)
                        : ''}
                  </ReportOverviewLabel>
                  <If condition={report?.state?.breathFrequency ? true : false}>
                    <ReportOverviewLabel size={16}>
                      &bull;{' '}
                      {t('frequencyPerMinute', {
                        value: report?.state?.breathFrequency || '0'
                      })}
                    </ReportOverviewLabel>
                  </If>
                </ReportOverviewInfoGroup>
                <ReportOverviewInfoGroup style={{ width: '50%' }}>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('pain')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    &bull;{' '}
                    {report?.state?.painLevel &&
                      getPainLevel(report?.state?.painLevel)}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
              </RowContainer>

              <RowContainer>
                <ReportOverviewInfoGroup style={{ width: '50%' }}>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('headNeckBack')}
                  </ReportOverviewLabel>

                  <If condition={report?.state?.headInjury ?? false}>
                    <ReportOverviewLabel size={16}>
                      &bull; {t('possibleHeadInjury')}
                    </ReportOverviewLabel>
                  </If>
                  <If condition={report?.state?.neckInjury ?? false}>
                    <ReportOverviewLabel size={16}>
                      &bull; {t('possibleNeckInjury')}
                    </ReportOverviewLabel>
                  </If>
                  <If condition={report?.state?.backInjury ?? false}>
                    <ReportOverviewLabel size={16}>
                      &bull; {t('possibleBackInjury')}
                    </ReportOverviewLabel>
                  </If>
                </ReportOverviewInfoGroup>

                <If condition={report?.state?.bigBleedings ? true : false}>
                  <ReportOverviewInfoGroup style={{ width: '50%' }}>
                    <ReportOverviewLabel
                      padding={'5px 0 10px 0'}
                      size={18}
                      weight={600}
                    >
                      {t('bigBleedings')}
                    </ReportOverviewLabel>

                    <ReportOverviewLabel size={16}>
                      &bull; {report?.state?.bigBleedings ? t`yes` : t`no`}
                    </ReportOverviewLabel>
                    <ReportOverviewLabel size={16}>
                      &bull;<b> {t`Internal`}: </b>
                      {report?.state?.bleedingsSource?.internal
                        ? t`yes`
                        : t`no`}
                    </ReportOverviewLabel>
                    <ReportOverviewLabel size={16}>
                      &bull; <b>{t`External`}: </b>
                      {report?.state?.bleedingsSource?.internal
                        ? t`yes`
                        : t`no`}
                    </ReportOverviewLabel>
                  </ReportOverviewInfoGroup>
                </If>
              </RowContainer>
              <RowContainer>
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {`${t('intoxicated')}`}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    &bull; {t(toCamelCase(report?.state?.intoxicated))}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
              </RowContainer>
            </ColContainer>
          </ReportOverviewCard>
          <If condition={report?.weather ? true : false}>
            <ReportOverviewCard title="weather">
              <WeatherCard weather={report?.weather} />
            </ReportOverviewCard>
          </If>
        </ReportOverviewBodyContentContainer>

        <ReportOverviewBodyContentContainer>
          <ReportOverviewCard title="timeStamps">
            <ColContainer>
              <RowContainer>
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('notificationOfInjury')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    {report?.timestamps?.notificationOfInjuryTime}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {report?.season === 'Winter'
                      ? t('skiPatrolOnInjurySite')
                      : t('pathPatrolOnInjurySite')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    {report?.season === 'Winter'
                      ? report?.timestamps?.skiPatrolAtInjurySite
                      : report?.timestamps?.pathPatrolAtInjurySite}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('pulkArrived')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    {report?.timestamps?.pulkArrivedTime || '-'}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('amisNumber')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    {report?.timestamps?.amisNumberTime || '-'}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
              </RowContainer>
              <RowContainer>
                <ReportOverviewInfoGroup style={{ maxWidth: '35%' }}>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('departureFromInjurySite')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    {report?.timestamps?.departureFromInjurySiteTime || '-'}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>

                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('arrivedInjuryRoom')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    {report?.timestamps?.arrivedAtInjuryRoomTime || '-'}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('amkWarning')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    {report?.timestamps?.amkWarningTime || '-'}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('patientDelivered')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    {report?.timestamps?.patientDeliveredTime || '-'}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
              </RowContainer>
              <RowContainer>
                <ReportOverviewInfoGroup>
                  <ReportOverviewLabel
                    padding={'5px 0 10px 0'}
                    size={18}
                    weight={600}
                  >
                    {t('policeWarned')}
                  </ReportOverviewLabel>
                  <ReportOverviewLabel size={16}>
                    {report?.timestamps?.policeWarnedTime || '-'}
                  </ReportOverviewLabel>
                </ReportOverviewInfoGroup>
              </RowContainer>
            </ColContainer>
          </ReportOverviewCard>
          <ReportOverviewCard title="treatedBy">
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('treatedBy')}
              </ReportOverviewLabel>
              {filterPropsByValue(report?.treatedBy, true).map(
                (field, index) => (
                  <ReportOverviewLabel key={index} size={16}>
                    &bull; {t(field.keyName)}
                  </ReportOverviewLabel>
                )
              )}
            </ReportOverviewInfoGroup>
          </ReportOverviewCard>
          <ReportOverviewCard title="checklist">
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('treatmentChecklist')}
              </ReportOverviewLabel>
              {filterPropsByValue(report?.checklist, true).map(
                (field, index) => (
                  <ReportOverviewLabel key={index} size={16}>
                    &bull;{' '}
                    {field.keyName === 'other'
                      ? report?.checklist?.otherAddComment
                      : t(field.keyName)}
                  </ReportOverviewLabel>
                )
              )}
            </ReportOverviewInfoGroup>
          </ReportOverviewCard>

          <ReportOverviewCard title="transport">
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('atResort')}
              </ReportOverviewLabel>

              {report &&
                report.transport &&
                report.transport.transportAtResort &&
                filterPropsByValue(
                  report?.transport?.transportAtResort,
                  true
                ).map((field, index) => (
                  <ReportOverviewLabel key={index} size={16}>
                    &bull;{' '}
                    {field.keyName === 'other'
                      ? report?.transport?.transportAtResort.otherAddComment
                      : t(field.keyName)}
                  </ReportOverviewLabel>
                ))}
            </ReportOverviewInfoGroup>
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('furtherTransport')}
              </ReportOverviewLabel>

              {report &&
                report.transport &&
                report.transport.furtherTransport &&
                filterPropsByValue(
                  report?.transport?.furtherTransport,
                  true
                ).map((field, index) => (
                  <ReportOverviewLabel key={index} size={16}>
                    &bull;{' '}
                    {field.keyName === 'other'
                      ? report?.transport?.furtherTransport.otherAddComment
                      : t(field.keyName)}
                  </ReportOverviewLabel>
                ))}
            </ReportOverviewInfoGroup>
          </ReportOverviewCard>

          <If
            condition={
              report?.investigationAndMonitoring &&
              report?.investigationAndMonitoring?.monitoring.length > 0
                ? true
                : false
            }
          >
            <ReportOverviewCard title="monitoring">
              <ColContainer>
                {report?.investigationAndMonitoring?.monitoring.map(
                  (monitoring, index) => (
                    <MonitoringCard monitoring={monitoring} key={index} />
                  )
                )}
              </ColContainer>
            </ReportOverviewCard>
          </If>
          <ReportOverviewCard title="therapists">
            <ReportOverviewInfoGroup>
              <ReportOverviewLabel color="#2b2b2b" size={18} weight={600}>
                {report?.mainCreator?.fullName} {' - '}{' '}
                {report?.mainCreator?.numericId}
              </ReportOverviewLabel>
              {report?.additionalCreators &&
                report.additionalCreators.map((creator, index) => (
                  <ReportOverviewLabel
                    key={index}
                    color="#2b2b2b"
                    size={18}
                    weight={600}
                  >
                    {creator?.fullName} {' - '} {creator?.numericId}
                  </ReportOverviewLabel>
                ))}
            </ReportOverviewInfoGroup>
          </ReportOverviewCard>

          <ReportOverviewCard title="mapAndTrail">
            <ReportOverviewInfoGroup style={{ width: '30%' }}>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('slopeTrail')}
              </ReportOverviewLabel>
              <ReportOverviewLabel size={16}>
                &bull; {report?.trailMap?.slope}
              </ReportOverviewLabel>
            </ReportOverviewInfoGroup>
            <ReportOverviewInfoGroup style={{ width: '70%' }}>
              <ReportOverviewLabel
                padding={'5px 0 10px 0'}
                size={18}
                weight={600}
              >
                {t('exactLocation')}
              </ReportOverviewLabel>

              <If condition={report?.trailMap && staticMap ? true : false}>
                <img src={staticMap} alt="slope map" />
              </If>
            </ReportOverviewInfoGroup>
          </ReportOverviewCard>
        </ReportOverviewBodyContentContainer>
      </ReportOverviewBodyContainer>
    </ReportOverViewPrintContainer>
  )
}

const ReportOverView = forwardRef(ReportOverViewFunction)

export default ReportOverView
